import React, { useState, useEffect } from "react";
import green_tick from "../Assets/Images/green_tick.svg";
import {
  APL_LINK,
  get_all_pricing,
  server_post_data,
  intent_for_payment,
  complete_payment_from_web,
  create_subscription,
  complete_subscription,
} from "../ServiceConnection/serviceconnection";
import {
  handleError,
  handleLinkClick,
  handleSuccess,
} from "../CommonJquery/CommonJquery";
import Loader from "./Loader.js";
import { retrieveData, storeData } from "../LocalConnection/LocalConnection.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const PricingModal = () => {
  const user_id = retrieveData("user_id");
  const [packages, setpackages] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [rupess_left, setrupess_left] = useState([]);
  const [rupess_right, setrupess_right] = useState([]);
  const [imagepath, setimagepath] = useState([]);
  const [month, setmonth] = useState(true);
  const [year, setyear] = useState(false);
  const storedPackageName = retrieveData("package_name");
  const subscription_active = retrieveData("subscription_active");
  // Retrieve package_name from local storage
  const navigate = useNavigate();
  const handlePricTabs = (tab) => {
    setmonth(tab === "month");
    setyear(tab === "year");
  };

  const master_data_get_pricing = async (flag) => {
    const fd = new FormData();
    fd.append("flag", flag);

    await server_post_data(get_all_pricing, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setpackages(Response.data.message.data);
          setrupess_left(Response.data.message.rupess_left);
          setrupess_right(Response.data.message.rupess_right);
          setimagepath(Response.data.message.image_path);
          console.log(Response.data.message.data);
        }
      })
      .catch((error) => {
        handleError("network");
      });
  };

  useEffect(() => {
    master_data_get_pricing(0);
  }, []);

  const handleShow = (name, id, action_link = true) => {
    if (action_link === true) {
      handleLinkClick(name, "_blank");
    } else {
      handleSuccess(name);
    }
  };

  // Separate packages for the two tabs
  const monthPackages = packages.filter(
    (item) => item.package_month_year == "Months"
  );
  const yearPackages = packages.filter(
    (item) => item.package_month_year == "Years"
  );

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const createRazorpayOrder = (
    amount,
    package_name,
    package_id,
    total_days
  ) => {
    let data = JSON.stringify({
      amount: amount * 100,
      currency: "INR",
      package_name: package_name,
      package_id: package_id,
      total_days: total_days,
      user_id: user_id,
    });
    if (user_id !== null && user_id !== "" && user_id !== "0") {
      setshowLoaderAdmin(true);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: intent_for_payment,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setshowLoaderAdmin(false);
          handleRazorpayScreen(
            response.data.message.amount,
            response.data.message.order_id,
            response.data.message.order_inserted_id,
            response.data.message.razorpay_key,
            response.data.message.image_link
          );
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    } else {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  };

  const handleRazorpayScreen = async (
    amount,
    order_id,
    order_inserted_id,
    razorpay_key,
    image_link
  ) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Some error at razorpay screen loading");
      return;
    }

    const options = {
      key: razorpay_key,
      amount: amount,
      currency: "INR",
      name: "Logo Acedamy",
      description: "Payment to Logo Acedamy",
      image: image_link,
      handler: function (response) {
        setshowLoaderAdmin(true);
        storeData("client_secret", response.razorpay_payment_id);
        const fd = new FormData();
        fd.append("payment_id", response.razorpay_payment_id);
        fd.append("order_inserted_id", order_inserted_id);
        fd.append("order_id", order_id);
        fd.append("customer_id", user_id);
        fd.append("payment_transaction_id", response.razorpay_payment_id);
        server_post_data(complete_payment_from_web, fd)
          .then(async (Response) => {
            setshowLoaderAdmin(false);
            if (Response.data.error) {
              handleError(Response.data.message);
            } else {
              navigate(`/complete_payment/${user_id}`);
            }
          })
          .catch((error) => {
            handleError("network");
            setshowLoaderAdmin(false);
          });
      },
      prefill: {
        name: "logoacademy",
        email: "mohsinkhanphp@gmail.com",
      },
      order_id: order_id,
      theme: {
        color: "#F4C430",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // const createRazorpaySubscription = (
  //   plan_id, // Razorpay Plan ID for the subscription
  //   user_email,
  //   user_name,
  //   package_id,
  //   Price,
  //   package_name,
  //   total_days
  // ) => {
  //   let data = JSON.stringify({
  //     plan_id: plan_id,
  //     user_id: user_id,
  //     email: user_email,
  //     name: user_name,
  //     package_id: package_id,
  //     Price: Price,
  //     package_name: package_name,
  //     total_days: total_days
  //   });

  //   if (user_id !== null && user_id !== "" && user_id !== "0") {
  //     setshowLoaderAdmin(true);
  //     let config = {
  //       method: "post",
  //       url: create_subscription, // URL to your backend subscription API
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         setshowLoaderAdmin(false);
  //         // Call Razorpay subscription handler with the subscription details
  //         console.log("Hello")
  //         console.log(response.data.message.subscription_id)
  //         handleRazorpaySubscriptionScreen(
  //           response.data.message.subscription_id,
  //           response.data.message.razorpay_key,
  //           response.data.message.image_link
  //         );
  //       })
  //       .catch((error) => {
  //         setshowLoaderAdmin(false);
  //       });
  //   } else {
  //     var event = new CustomEvent("customEvent");
  //     document.getElementById("login_check_jquery").dispatchEvent(event);
  //   }
  // };

  // const handleRazorpaySubscriptionScreen = async (
  //   subscription_id,
  //   razorpay_key,
  //   image_link
  // ) => {

  //   const res = await loadScript(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );
  //   if (!res) {
  //     alert("Some error occurred while loading the Razorpay screen.");
  //     return;
  //   }

  //   const options = {
  //     key: razorpay_key,
  //     subscription_id: subscription_id, // Pass the subscription ID here
  //     name: "Logo Academy",
  //     description: "Subscription Payment",
  //     image: image_link,
  //     handler: function (response) {
  //       setshowLoaderAdmin(true);
  //       // Store the payment details (like the razorpay_payment_id)
  //       const fd = new FormData();
  //       fd.append("subscription_id", subscription_id);
  //       fd.append("payment_id", response.razorpay_payment_id);
  //       fd.append("user_id", user_id);

  //       // Call backend to confirm subscription
  //       server_post_data(complete_subscription, fd)
  //         .then(async (Response) => {
  //           setshowLoaderAdmin(false);
  //           if (Response.data.error) {
  //             handleError(Response.data.message);
  //           } else {
  //             navigate(`/complete_payment/${user_id}`);
  //           }
  //         })
  //         .catch((error) => {
  //           handleError("network");
  //           setshowLoaderAdmin(false);
  //         });
  //     },
  //     prefill: {
  //       name: "",
  //       email: "",
  //     },
  //     theme: {
  //       color: "#F4C430",
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // };
  const [selectedCurrencyTab, setSelectedCurrencyTab] = useState("IND");
  const [currencySymbol, setCurrencySymbol] = useState("₹");
  const [Monthprice, setMonthprice] = useState(100);
  const [Yearprice, setYearprice] = useState(120);

  const MonthNumprices = {
    IND: { symbol: "₹", value: 100 },
    EUR: { symbol: "€", value: 80 },
    USD: { symbol: "$", value: 90 },
  };
  const YearNumprices = {
    IND: { symbol: "₹", value: 120 },
    EUR: { symbol: "€", value: 100 },
    USD: { symbol: "$", value: 110 },
  };
  const handleTabClick = (currency) => {
    setSelectedCurrencyTab(currency);
    setCurrencySymbol(MonthNumprices[currency].symbol);
    setMonthprice(MonthNumprices[currency].value);
    setYearprice(YearNumprices[currency].value);
  };
  return (
    <>
      <div className="d-flex justify-content-center py-1">
        <div className="pricingTabs">
          <div
            className={`priceTab ${month === true ? "selectPriceTab" : ""}`}
            onClick={() => handlePricTabs("month")}
          >
            <p>3 Months Editing Period</p>
          </div>
          <div
            className={`priceTab ${year === true ? "selectPriceTab" : ""}`}
            onClick={() => handlePricTabs("year")}
          >
            <p>1 Year Editing Period</p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center py-1">
        <div className="pricingTabs">
          <div
            className={`priceTab ${
              selectedCurrencyTab === "IND" ? "selectPriceTab" : ""
            }`}
            onClick={() => handleTabClick("IND")}
          >
            <p>IND</p>
          </div>
          <div
            className={`priceTab ${
              selectedCurrencyTab === "EUR" ? "selectPriceTab" : ""
            }`}
            onClick={() => handleTabClick("EUR")}
          >
            <p>EUR</p>
          </div>
          <div
            className={`priceTab ${
              selectedCurrencyTab === "USD" ? "selectPriceTab" : ""
            }`}
            onClick={() => handleTabClick("USD")}
          >
            <p>USD</p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row CenterDiv mt-4">
          {showLoaderAdmin && <Loader />}
          {month && (
            <>
              {monthPackages.map((item, index) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3"
                  key={index}
                >
                  <div className="card ">
                    <div className="card-body card_body">
                      <h6 className="card-title"> {item.package_name}</h6>
                      {/* <h3>
                        {currencySymbol} {rupess_left}
                        {item.Price}
                        {rupess_right}/-
                      </h3> */}
                      <h3>
                        {currencySymbol}
                        {Monthprice}
                        /-
                      </h3>

                      <div className="pricing_button_container">
                        <button
                          className="btn btn-primary"
                          onClick={
                            () =>
                              createRazorpayOrder(
                                item.Price,
                                item.package_name,
                                item.primary_id,
                                item.package_days
                              )
                            // createRazorpaySubscription(
                            //   item.primary_id,
                            //   "",
                            //   "",
                            //   item.primary_id,
                            //   item.Price,
                            //   item.package_name,
                            //   item.package_days
                            // )
                          }
                          disabled={
                            item.package_name === storedPackageName &&
                            subscription_active == 1
                          } // Disable if package_name matches
                        >
                          {item.package_name === storedPackageName &&
                          subscription_active == 1
                            ? "Purchased"
                            : "Continue"}
                        </button>
                      </div>
                      <h6 className="file_options">
                        {" "}
                        <strong>Logo file options</strong>
                      </h6>
                      <div className="benefits single_li_card">
                        <ul className="custom_list">
                          {item.transparent_background !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.transparent_background}
                            </li>
                          )}
                          {item.resolution !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.resolution}
                            </li>
                          )}
                          {item.vector_file !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.vector_file}
                            </li>
                          )}
                        </ul>
                        {(item.brand_identity !== "" ||
                          item.favicon !== "" ||
                          item.watermark !== "" ||
                          item.business_card !== "") && (
                          <h6 className="file_options">
                            <strong>Brand Identity</strong>
                          </h6>
                        )}
                        <ul className="custom_list">
                          {item.favicon !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.favicon}
                            </li>
                          )}
                          {item.watermark !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.resizing_design}
                            </li>
                          )}
                          {item.business_card !== "" && (
                            <>
                              <li>
                                <img
                                  src={green_tick}
                                  id="green_tick"
                                  alt="green tick"
                                />{" "}
                                {item.business_card}
                              </li>
                              <li>
                                <img
                                  src={green_tick}
                                  id="green_tick"
                                  alt="green tick"
                                />{" "}
                                Letterhead
                              </li>
                              <li>
                                <img
                                  src={green_tick}
                                  id="green_tick"
                                  alt="green tick"
                                />{" "}
                                Envelope
                              </li>
                            </>
                          )}
                          {item.brand_identity !== "" && (
                            <>
                              <li>
                                <img
                                  src={green_tick}
                                  id="green_tick"
                                  alt="green tick"
                                />{" "}
                                Brand Identity
                              </li>
                            </>
                          )}
                        </ul>
                        {item.copyright_ownership !== "" && (
                          <h6 className="file_options">
                            <strong>Platform access</strong>
                          </h6>
                        )}
                        <ul className="custom_list">
                          {item.new_version_for_logo !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.new_version_for_logo}
                            </li>
                          )}
                          {item.unlimited_changes !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.unlimited_changes}
                            </li>
                          )}
                          {item.copyright_ownership !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.copyright_ownership}
                            </li>
                          )}
                          {item.support !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.support}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {item.file_example_download !== "" &&
                      item.file_example_download !== null && (
                        <span id="see_example">
                          <hr />
                          <button
                            className="downloadSampleBtn"
                            onClick={() =>
                              handleShow(
                                APL_LINK +
                                  imagepath +
                                  item.file_example_download,
                                item.primary_id,
                                true
                              )
                            }
                          >
                            <p
                              style={{
                                marginTop: "10px",
                                color: "#175AC8",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              Download Example{" "}
                            </p>
                          </button>
                        </span>
                      )}
                  </div>
                </div>
              ))}
            </>
          )}
          {year && (
            <>
              {yearPackages.map((item, index) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3"
                  key={index}
                >
                  <div className="card ">
                    <div className="card-body card_body">
                      <h6 className="card-title"> {item.package_name}</h6>
                      {/* <h3>
                        {rupess_left}
                        {item.Price}
                        {rupess_right}/-
                      </h3> */}

                      <h3>
                        {currencySymbol}
                        {Yearprice}
                        /-
                      </h3>
                      <div className="pricing_button_container">
                        <button
                          className="btn btn-primary"
                          onClick={
                            () =>
                              createRazorpayOrder(
                                item.Price,
                                item.package_name,
                                item.primary_id,
                                item.package_days
                              )
                            // createRazorpaySubscription(
                            //   item.primary_id,
                            //   "",
                            //   "",
                            //   item.primary_id,
                            //   item.Price,
                            //   item.package_name,
                            //   item.package_days
                            // )
                          }
                          disabled={item.package_name === storedPackageName} // Disable if package_name matches
                        >
                          {item.package_name === storedPackageName
                            ? "Purchased"
                            : "Continue"}
                        </button>
                      </div>
                      <h6 className="file_options">
                        {" "}
                        <strong>Logo file options</strong>
                      </h6>
                      <div className="benefits single_li_card">
                        <ul className="custom_list">
                          {item.transparent_background !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.transparent_background}
                            </li>
                          )}
                          {item.resolution !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.resolution}
                            </li>
                          )}
                          {item.vector_file !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.vector_file}
                            </li>
                          )}
                        </ul>
                        {item.brand_identity !== "" && (
                          <h6 className="file_options">
                            <strong>{item.brand_identity}</strong>
                          </h6>
                        )}
                        <ul className="custom_list">
                          {item.favicon !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.favicon}
                            </li>
                          )}
                          {item.watermark !== "" && <li></li>}
                          {item.resizing_design !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.resizing_design}
                            </li>
                          )}
                          {item.business_card !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.business_card}
                            </li>
                          )}
                        </ul>
                        {item.new_version_for_logo !== "" && (
                          <h6 className="file_options">
                            <strong>Platform access</strong>
                          </h6>
                        )}
                        <ul className="custom_list">
                          {item.new_version_for_logo !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.new_version_for_logo}
                            </li>
                          )}
                          {item.unlimited_changes !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.unlimited_changes}
                            </li>
                          )}
                          {item.copyright_ownership !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.copyright_ownership}
                            </li>
                          )}
                          {item.support !== "" && (
                            <li>
                              <img
                                src={green_tick}
                                id="green_tick"
                                alt="green tick"
                              />{" "}
                              {item.support}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {item.file_example_download !== "" &&
                      item.file_example_download !== null && (
                        <span id="see_example">
                          <hr />
                          <button
                            className="downloadSampleBtn"
                            onClick={() =>
                              handleShow(
                                APL_LINK +
                                  imagepath +
                                  item.file_example_download,
                                item.primary_id,
                                true
                              )
                            }
                          >
                            <p
                              style={{
                                marginTop: "10px",
                                color: "#175AC8",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              Download Example{" "}
                            </p>
                          </button>
                        </span>
                      )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PricingModal;
