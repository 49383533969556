import React, { useState } from "react";
import "../Components/Css/Footer.css";
import "../Components/Css/LogoIdeas.css";
import earth from "../Assets/Images/earth.svg";
import fb_logo from "../Assets/Images/fb_logo.svg";
import ig_logo from "../Assets/Images/ig_logo.svg";
import linkedIn_logo from "../Assets/Images/linkedIn_logo.svg";
import x_logo from "../Assets/Images/x_logo.svg";
import footer_social_logo from "../Assets/Images/footer_social_logo.svg";
import pintrestLogo from "../Assets/Images/pintrestLogo.svg";
import headerLogo from "../Assets/Images/headerLogo.png";
import { Link } from "react-router-dom";
import { languages } from "../CommonJquery/WebsiteText";

const Footer = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].label);
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };
  return (
    <section className="footer_Section">
      <div className="container">
        <div className="footer_container">
          <footer className="page-footer font-small blue">
            <div className="container-fluid text-center text-md-left">
              <div className="row flexRowCenterEvenSpace">
                {/* First links column */}
                <div className="col-md-2 col-sm-6 col-6 mb-md-0 mb-3 footer_column1">
                  <h5 className="footer_column1_heading">Products</h5>
                  <ul className="list-unstyled ">
                    <li>
                      <Link to="/aboutus">
                        <a>About Us</a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/howItWorks">
                        <a>How it works </a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/pricing">
                        <a>Pricing </a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/createlogohome">
                        <a>Logo Maker </a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/createlogohome">
                        <a>Logo Design </a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/createlogohome">
                        <a>Logo Generator </a>
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* Third links column */}
                <div className="col-md-2 col-sm-6 col-6 mb-md-0 mb-3 footer_column3">
                  <h5 className="footer_column3_heading">Inspiration</h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/blogs">
                        <a>Blog</a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/logoIdeas">
                        <a href="/logoIdeas">Logo Ideas</a>
                      </Link>
                    </li>
                    <li>
                      <h5 className="footer_column3_heading  supportHead">
                        Support
                      </h5>
                      <ul className="list-unstyled">
                        <li>
                          <Link to="/faqs">
                            <a>FAQ</a>
                          </Link>
                        </li>
                        <li>
                          <Link to="/support">
                            <a>Help Center</a>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>

                {/* Fourth column for company logo and social links */}
                <div className="col-md-6 mb-md-0 mb-3 text-md-right footer_column4">
                  <img src={headerLogo} alt="header logo" />
                  <div className="footer_column4_text">
                    <p>
                      Logo Academy is a revolutionary branding platform that
                      allows users to create consistent, impactful brand assets
                      with just a click. From logos to business cards, we
                      provide everything you need to position your brand
                      uniquely in the market.
                    </p>
                  </div>
                  {/* Add your company logo and social links here */}
                  <div className="footer_column4_socials flexCol py-3">
                    <div className="socialLinKK">
                      <a
                        href="https://www.facebook.com/profile.php?id=61560246291563"
                        className="social-icon"
                        target="blank"
                      >
                        <img src={fb_logo} alt="social_icon" />
                      </a>
                      <a
                        href="https://www.instagram.com/logoacademy.co/"
                        className="social-icon"
                        target="blank"
                      >
                        <img src={ig_logo} alt="social_icon" />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/logo-academy-b564bb314/"
                        className="social-icon"
                        target="blank"
                      >
                        <img src={linkedIn_logo} alt="social_icon" />
                      </a>
                      <a
                        href="https://x.com/Logoacademy1"
                        className="social-icon"
                        target="blank"
                      >
                        <img src={x_logo} alt="social_icon" />
                      </a>
                      {/* <a
                        href="  https://dribbble.com/logoacademy1"
                        className="social-icon"
                        target="blank"
                      >
                        <img src={footer_social_logo} alt="social_icon" />
                      </a> */}
                      <a
                        href="https://in.pinterest.com/logoacademy1/"
                        className="social-icon"
                        target="blank"
                      >
                        <img src={pintrestLogo} alt="social_icon" />
                      </a>
                    </div>
                    <div className="footer-copyright flexCol text-center">
                      <ul className="footer-links">
                        {/* <li>
                          <Link
                            id="selectedLanguage"
                            className="dropdown-toggle"
                            to="/"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              alt="Logo Academy"
                              src={earth}
                              id="earth"
                              width={"15px"}
                              className="footerLanguageEarthIcon"
                            />
                            {selectedLanguage}
                          </Link>
                          <ul className="dropdown-menu dropdown_menuFooter ">
                            {languages.map((language, index) => (
                              <li key={index}>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleLanguageChange(language.label)
                                  }
                                >
                                  {language.label}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </li> */}
                        <Link to="/privacyPolicy">
                          <li>Privacy Policy</li>
                        </Link>
                        <Link to="/termsofuse">
                          <li>Terms</li>
                        </Link>

                        <Link to="/refund-policy">
                          <li>Refund Policy</li>
                        </Link>
                        <Link to="/licensing">
                          <li>Licensing</li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default Footer;
